import {ISubDomainData} from '~/components/Client/SubDomainDisplay/subDomainData';
import {COLORS} from '~/components/Common/Colors';
import {CONSENT_TYPE, CONSENT_STATUS, Relationship, CONSENT_ACTIONS} from '~/constants';

export interface IClientBasicInfoErrors {
    FSR: string;
    CANVAS: string;
    GENERAL: string;
}

export interface IClientBasicInfoErrorCodes {
    FSR: string;
    CANVAS: string;
    GENERAL: string;
}

export const FSR_ERROR_CODE = {
    MULTIHIT: '409',
    NOTFOUND: '404',
};

export enum UINType {
    NRIC = 0,
    FIN = 1,
    Other = 2,
}

export interface IFamilyMember {
    Name: string;
    Relationship: Relationship;
    UIN: string;
    UINType?: UINType;
    MaskedUIN?: string;
    DateOfBirth?: string;
    Consent?: IConsent;
    IsDeceased?: boolean;
    HashedUin?: string;
}
export interface IClientBasicInfo {
    Name: string;
    UIN: string;
    Address: string;
    ResidentialStatus: 'Singaporean' | 'Permanent Resident' | 'Foreigner';
    DateOfBirth: Date;
    Sex: 'Male' | 'Female';
    FamilyMembers: IFamilyMember[];
    RecentSearches: IRecentSearchEntry[];
    ClientConsent?: IConsent;
    Errors: IClientBasicInfoErrors;
    ErrorCodes: IClientBasicInfoErrorCodes;
    IsDeceased?: boolean;
    HashedUin: string;
}

export interface IConsent {
    Id: number;
    Agency: string;
    CreatedAt: Date;
    ConsentType: CONSENT_TYPE;
    CreatedBy: string;
    PartialUIN: string;
    MinorConsentDate?: Date;
    Status: CONSENT_STATUS;
    ConsentForm: IConsentFileMeta | null;
    ConsentHistory: IConsentHistory[] | null;
}

export interface IConsentHistory {
    Id: number;
    EffectiveDate?: Date;
    Remarks: string;
    UpdatedBy: string;
    UpdatedAt?: Date;
    ConsentForm: IConsentFileMeta | null;
    FormType: string;
    Type: CONSENT_ACTIONS;
}

export interface IConsentAndSearchHistory {
    RecentSearches: IRecentSearchEntry[];
    ClientConsent: IConsent;
}

export interface IConsentFileMeta {
    Id: number;
    UserId: number;
    FileHash: string;
    CreatedAt: Date;
}

export interface IConsentRequest {
    Password: string;
}

export interface IScreeningRequest {
    ClientUIN: string;
    ClientName: string;
    SubDomains: string[];
}

export interface IScreeningRequestResponse {
    ScreeningRequestId: string;
}

export interface IRecentSearchEntry {
    Id: number;
    Agency: string;
    Team: string;
    OpsUnit: string;
    Name: string;
    Email: string;
    Date: string;
}

export interface IClientInfo {
    ScreeningRequestId: string;
    GeneralInfo: {
        ClientName: string;
        ClientUIN: string;
        ClientConsent?: IConsent;
        Relationship: Relationship;
        ScreenedDate: string;
        IsDeceased?: boolean;
    };
    SubDomainInfo: {
        [subDomainCode: string]: {
            Status: SubDomainStatus;
            LastUpdatedAt: string;
            Data: ISubDomainData;
            Source: string;
            ErrorMessages?: string[];
        }[];
    };
}

export interface IFamilyScreeningResults {
    FamilyScreeningRequestId: string;
    ScreeningResults: IClientInfo[];
}

export enum SubDomainStatus {
    COMPLETED = 'completed',
    ERROR = 'error',
    PENDING = 'pending',
}

export const StatusColor = {
    [SubDomainStatus.COMPLETED]: COLORS.GREEN,
    [SubDomainStatus.ERROR]: COLORS.RED,
    [SubDomainStatus.PENDING]: COLORS.ORANGE,
};

export interface IScreeningReportRequest {
    FamilyScreeningRequestId: string;
    ScreeningRequestIds: string[];
    Password: string;
    SelectedSubDomains: string[];
    SingleReport: boolean;
}

export interface IScreenedClients {
    [screeningRequestId: string]: {
        FamilyScreeningRequestId: string;
        ScreeningResults: IClientInfo[];
    };
}
