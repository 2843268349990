import {Card, CardContent, Grid, List, ListItem, Typography, Link as MuiLink} from '@mui/material';
import React, {useRef, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ClientInfoHeader} from '~/components/Client/ClientInfoHeader/ClientInfoHeader';
import {getAlphabeticalComparator, getDateComparator} from '~/components/Common/ListModel/comparators';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import {FSR_ERROR_CODE, IRecentSearchEntry} from '~/interfaces/client';
import {formatDate} from '~/utils/dateUtils';
import {ClientInfoFooter} from '~/components/Client/ClientInfoFooter/ClientInfoFooter';
import {config} from '~/config';
import styles from './BasicInfo.scss';
import {ACCESS_LEVELS, MAIL_TO_LINK, OPS_UNIT_TYPE} from '~/constants';
import {AuthenticationContext} from '~/utils/contexts/authentication/authenticationContext';
import {useTypedSelector} from '~/hooks/useTypedSelector';
import {AdvisoryNoteModal} from '../AdvisoryNoteModal/AdvisoryNoteModal';

const WrappedTypography: React.FunctionComponent<{value?: string}> = ({value}) => {
    return (
        <Typography component={'div'} variant="body1">
            {value ? value : '-'}
        </Typography>
    );
};

interface IBasicInfoKV {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
}

interface IBasicInfoMap {
    canvas: IBasicInfoKV[];
    fsr: IBasicInfoKV[];
}

interface IMainInfoCardProps {
    showErrorCard: boolean;
    errorMessage: JSX.Element | string;
    basicInfoMap: IBasicInfoMap;
}

const MainInfoCard = ({showErrorCard, errorMessage, basicInfoMap}: IMainInfoCardProps) => {
    return (
        <Card id="mainInfoCard" styleName="sectionCard">
            <CardContent styleName="sectionCardContent">
                <Typography variant="h5" styleName="sectionTitle">
                    Client&apos;s Profile
                </Typography>
                {showErrorCard ? (
                    <div styleName="errorContainer">{errorMessage}</div>
                ) : (
                    <List>
                        {basicInfoMap.canvas.map(({name, value}) => (
                            <ListItem disableGutters key={name}>
                                <Typography variant="subtitle1" styleName="infoListTitle">
                                    {name}
                                </Typography>
                                <WrappedTypography value={value} />
                            </ListItem>
                        ))}
                        {basicInfoMap.fsr.map(({name, value}) => (
                            <ListItem disableGutters key={name}>
                                <Typography variant="subtitle1" styleName="infoListTitle">
                                    {name}
                                </Typography>
                                <WrappedTypography value={value} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </CardContent>
        </Card>
    );
};

const contactUs = (
    <MuiLink href={MAIL_TO_LINK} target="_blank" rel="noopener noreferrer" underline="always">
        contact
    </MuiLink>
);

const FSR_BAD_REQUEST_COPY = (
    <Typography variant="subtitle2" styleName="fsrErrorCopy">
        This information is temporarily unavailable. Please {contactUs} the Administrators or check again later. You may
        still proceed to request more information.
    </Typography>
);

const FSR_MULTIPLE_COPY = (
    <Typography variant="subtitle2" styleName="fsrErrorCopy">
        This information could not be retrieved. Please {contactUs} the Administrators and we will follow up with you
        shortly. You may still proceed to request more information.
    </Typography>
);

const FSR_NOT_FOUND = (
    <Typography variant="subtitle2" styleName="fsrErrorCopy">
        This information is not available. Please {contactUs} the Administrators and we will follow up with you shortly.
        You may still proceed to request more information.
    </Typography>
);

const getFsrErrorMessage = (errorCode: string | undefined) => {
    switch (errorCode) {
        case FSR_ERROR_CODE.MULTIHIT:
            return FSR_MULTIPLE_COPY;
        case FSR_ERROR_CODE.NOTFOUND:
            return FSR_NOT_FOUND;
        default:
            return FSR_BAD_REQUEST_COPY;
    }
};

export const BasicInfo = () => {
    const {accessToken} = useContext(AuthenticationContext);
    const history = useHistory();
    const headerRef = useRef<HTMLDivElement>(null);
    const basicInfo = useTypedSelector((state) => state.data.client.searchedClientInfo);
    const basicInfoErrors = basicInfo.Errors;
    const basicInfoErrorCodes = basicInfo.ErrorCodes;
    const isCanvasError = basicInfoErrors.CANVAS !== undefined;
    const showErrorCard = basicInfoErrors.FSR !== undefined || basicInfoErrors.GENERAL !== undefined;
    const fsrErrorMessage = getFsrErrorMessage(basicInfoErrorCodes?.FSR);

    const canViewFamilyInfo =
        accessToken &&
        (accessToken.Permissions.AccessLevel === ACCESS_LEVELS.SYSTEM_ADMINISTRATOR ||
            accessToken.AdditionalUserInfo.OpsUnitType === OPS_UNIT_TYPE.PSA);

    const basicInfoMap: IBasicInfoMap = {
        canvas: [
            // {
            //     name: 'Registered Address',
            //     value: isCanvasError ?
            //         <Typography variant="subtitle1">
            //             Please report the issue to us via &apos;<b>Contact Us</b>&apos; or the
            //             &apos;<b>Support</b>&apos; widget.
            //         </Typography> : basicInfo.Address
            // }
        ],
        fsr: [
            {name: 'Residential Status', value: basicInfo.ResidentialStatus},
            {
                name: 'Date of Birth',
                value: basicInfo.DateOfBirth
                    ? formatDate(new Date(basicInfo.DateOfBirth), true, true)
                    : basicInfo.DateOfBirth,
            },
            {name: 'Sex', value: basicInfo.Sex},
        ],
    };

    // Remove when CANVAS is ready
    if (config.featureFlags.ENABLE_CANVAS) {
        basicInfoMap.canvas.unshift({
            name: 'Registered Address',
            value: isCanvasError ? (
                <Typography variant="subtitle2" component="span">
                    Information could not be retrieved. Please report the issue to us via &apos;<b>Contact Us</b>&apos;
                    or the &apos;<b>Support</b>&apos; widget.
                </Typography>
            ) : (
                basicInfo.Address
            ),
        });
    }

    const agency = (entry: IRecentSearchEntry) => entry.Agency;
    const date = (entry: IRecentSearchEntry) => formatDate(new Date(entry.Date), false, true);
    const opsUnitAndTeam = (entry: IRecentSearchEntry) => (
        <div>
            <div>{entry.OpsUnit ? entry.OpsUnit : '-'}</div>
            <div styleName="recentSearchEntryTeam">{entry.Team}</div>
        </div>
    );
    const opsUnitAndTeamHeader = (
        <div>
            <div>Ops Unit</div>
            <div styleName="recentSearchEntryTeamHeader">Team</div>
        </div>
    );

    const officerNameAndEmail = (entry: IRecentSearchEntry) => (
        <div>
            <div>{entry.Name}</div>
            <div styleName="recentSearchEntryTeam">{entry.Email}</div>
        </div>
    );
    const officerNameAndEmailHeader = (
        <div>
            <div>Officer Name</div>
            <div styleName="recentSearchEntryTeamHeader">Officer Email</div>
        </div>
    );

    const handleFooterButtonClick = () => {
        history.push('/search/screen-client');
    };

    return (
        <>
            <div ref={headerRef}>
                <ClientInfoHeader
                    clientName={basicInfo.Name}
                    clientUIN={basicInfo.UIN}
                    clientConsent={basicInfo.ClientConsent}
                    isDeceased={basicInfo.IsDeceased}
                />
            </div>
            <Grid container spacing={4} id={styles.mainContainer}>
                <Grid item xs={8}>
                    <Grid item xs={12}>
                        <MainInfoCard
                            showErrorCard={showErrorCard}
                            errorMessage={fsrErrorMessage}
                            basicInfoMap={basicInfoMap}
                        />
                    </Grid>
                    <Grid item xs={12} id={styles.recentSearchHistory}>
                        <Typography variant="h5" styleName="recentSearchTitle">
                            Recent Searches
                        </Typography>
                        <Typography variant="subtitle1" styleName="recentSearchSubtitle">
                            These officers performed a search of this client in the past 3 months.
                        </Typography>

                        <ListModel
                            columns={[agency, opsUnitAndTeam, officerNameAndEmail, date]}
                            headers={['Agency', opsUnitAndTeamHeader, officerNameAndEmailHeader, 'Date']}
                            modelList={basicInfo.RecentSearches}
                            modelComparator={[
                                getAlphabeticalComparator(agency),
                                getAlphabeticalComparator((entry) => entry.Team + entry.OpsUnit),
                                getAlphabeticalComparator((entry) => entry.Name + entry.Email),
                                getDateComparator((entry) => new Date(entry.Date)),
                            ]}
                            defaultSortIndex={3}
                            defaultSortOrder="desc"
                            variant="expanded"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    {canViewFamilyInfo && (
                        <>
                            <Card id="immediateFamilyCard" styleName="sectionCard">
                                <CardContent styleName="sectionCardContent">
                                    <Typography variant="h5" styleName="sectionTitle">
                                        Immediate Family Members
                                    </Typography>
                                    {showErrorCard ? (
                                        getFsrErrorMessage(basicInfoErrorCodes?.FSR)
                                    ) : basicInfo.FamilyMembers ? (
                                        <List>
                                            {basicInfo.FamilyMembers.map((member) => (
                                                <ListItem disableGutters key={member.UIN} styleName="familyMemberItem">
                                                    <Typography variant="subtitle1" styleName="infoListTitle">
                                                        {member.Relationship}
                                                    </Typography>
                                                    <div>{member.MaskedUIN}</div>
                                                    <div>{member.Name}</div>
                                                </ListItem>
                                            ))}
                                        </List>
                                    ) : (
                                        <WrappedTypography />
                                    )}
                                </CardContent>
                            </Card>
                        </>
                    )}
                </Grid>
            </Grid>
            {basicInfo && (
                <ClientInfoFooter
                    clientName={basicInfo.Name}
                    clientUIN={basicInfo.UIN}
                    clientConsent={basicInfo.ClientConsent}
                    isButtonDisabled={false}
                    isButtonHidden={false}
                    isFooterHidden={true}
                    isButtonLoading={false}
                    headerRef={headerRef}
                    buttonText="REQUEST MORE INFO"
                    onButtonClick={handleFooterButtonClick}
                />
            )}
            {!!config.featureFlags.ENABLE_ADVISORY && <AdvisoryNoteModal />}
        </>
    );
};
