import {Divider, Grid, Snackbar, Typography} from '@mui/material';
import React, {useState, useRef} from 'react';
import {ClientSearchForm} from '~/components/Client/ClientSearchForm/ClientSearchForm';
import {SuccessMessage} from '~/components/Common/IconMessage/IconMessage';
import {CONSENT_STATUS, ERROR_MESSAGE, SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {IConsent} from '~/interfaces/client';
import {getClientConsentHistory, updateClientConsent} from '~/services/consentServices';
import {useGrowl} from '~/utils/hooks/useGrowl';
import {format} from 'date-fns';
import './ConsentEdit.scss';
import {ClientConsentTable} from './ConsentTable/ConsentTable';
import {ConsentUpdateDialog} from './ConsentUpdateDialog';
import {getConsentIdError} from '~/utils/inputUtils';
import _ from 'lodash';

export type ClientConsentData = {
    ClientName: string;
    ClientUin: string;
    ConsentHistory: IConsent[];
};

export const ConsentEdit = () => {
    const [hasData, setHasData] = useState<boolean>(false);
    const [clientConsentHistory, setClientConsentHistory] = useState<ClientConsentData>();
    const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [isNotFound, setIsNotFound] = useState<boolean>(false);
    const [consentModalState, setConsentModalState] = useState<string>('');
    const [constentModalStatus, setConsentModalStatus] = useState<CONSENT_STATUS>(CONSENT_STATUS.EXPIRED_CONSENT);
    const [editConsentId, setEditConsentID] = useState<number>(0);
    const [expandedRows, setExpandedRows] = useState<number[]>([]);
    const {growl, openGrowl, closeGrowl} = useGrowl();

    const uinRef = useRef('');

    const fetchData = async (uin: string) => {
        setError('');
        setIsNotFound(false);
        setHasData(false);
        setIsSearchLoading(true);

        try {
            const data = await getClientConsentHistory(uin);
            const partialUin =
                _.find(data.ConsentHistory, (consent: IConsent) => {
                    return consent.PartialUIN !== '';
                })?.PartialUIN || uin;
            setClientConsentHistory({...data, ClientUin: partialUin});
            setHasData(true);
        } catch (err) {
            const error = err as Error;
            if (error.message.trim() === ERROR_MESSAGE.CLIENT_CONSENT_NOT_FOUND_ERROR) {
                setIsNotFound(true);
            } else {
                setError(error.message);
            }
        }
        setIsSearchLoading(false);
    };

    const handleDelete = (consentId: number) => {
        setEditConsentID(consentId);
        setConsentModalState('delete');
    };

    const handleRevoke = (consentId: number) => {
        setEditConsentID(consentId);
        setConsentModalState('revoke');
    };

    const handleUpdate = (consentId: number) => {
        setEditConsentID(consentId);
        setConsentModalState('update');
    };

    const handleSearch = async (uin: string) => {
        uinRef.current = uin;
        setExpandedRows([]);
        fetchData(uin);
    };

    const updateConsent = async (remarks: string, effectiveDate: Date, action: string, consentFormId: number) => {
        if (editConsentId !== 0 && consentModalState.length !== 0) {
            try {
                const payload = {
                    Id: editConsentId,
                    Remarks: remarks,
                    EffectiveDate: format(new Date(effectiveDate), 'yyyy-MM-dd'),
                    ConsentFormId: consentFormId,
                };

                await updateClientConsent(action, payload);
                openGrowl(`Consent successfully ${action}d!`);
                setExpandedRows([editConsentId]);
            } catch (err) {
                const error = err as Error;
                openGrowl(error.message);
                setExpandedRows([]);
            }
            fetchData(uinRef.current);
        }
        setEditConsentID(0);
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Typography variant="h4">Consent Management</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography styleName="input-title">
                        Enter client&apos;s NRIC / FIN or Consent Serial Number
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <ClientSearchForm
                        disabled={isSearchLoading}
                        onSubmit={handleSearch}
                        placeholder="E.g. 'S1234567D'"
                        getValidationError={getConsentIdError}
                    />
                </Grid>
                {error && (
                    <Grid item xs={12} data-testid="error">
                        <Typography styleName="error-message">{error}</Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    {isNotFound && <SuccessMessage message={SUBDOMAIN_RESULT_MESSAGE.EMPTY} />}
                    {hasData && clientConsentHistory && (
                        <ClientConsentTable
                            data={clientConsentHistory}
                            setConsentStatus={setConsentModalStatus}
                            expandedRows={expandedRows}
                            handleDelete={handleDelete}
                            handleRevoke={handleRevoke}
                            handleUpdate={handleUpdate}
                            openGrowl={openGrowl}
                        />
                    )}
                </Grid>
            </Grid>
            <ConsentUpdateDialog
                status={constentModalStatus}
                action={consentModalState}
                onSubmit={updateConsent}
                onClose={() => {
                    setConsentModalState('');
                    setConsentModalStatus(CONSENT_STATUS.EXPIRED_CONSENT);
                }}
            />
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                key={growl?.key}
                open={growl.open}
                onClose={closeGrowl}
                autoHideDuration={growl.autoHideDuration}
                message={growl.message.length > 0 ? growl.message : undefined}
                ContentProps={{
                    style: {
                        textAlign: 'left',
                        width: '100%',
                    },
                }}
                style={{
                    transform: 'inherit',
                    left: '10%',
                    right: '10%',
                }}
            />
        </>
    );
};
